import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePartnerContext } from 'hooks/useContexts';
import PartnerFinderPresentation from './Presentations/PartnerFinderPresentation';
import MarketplaceFinderPresentation from './Presentations/MarketplaceFinderPresentation';
import KnighstFinderPresentation from './Presentations/KnighstFinderPresentation';
import EventsFinderPresentation from './Presentations/EventsFinderPresentation';
import StandaloneBanner from './StandaloneBanner';
import UseCasesFinderPresentation from './Presentations/UseCasesFinderPresentation';
import CSPFinderPresentation from './Presentations/CSPFinderPresentation';

export default function FinderToolsPresentation({
  tab,
  standalone
}: {
  tab: string;
  standalone: boolean;
}) {
  const { t } = useTranslation();
  const { setLoadingInfo } = usePartnerContext();

  useEffect(() => {
    setLoadingInfo(false);
  }, []);

  return (
    <div className="w-full h-full flex flex-col gap-8 pb-8">
      {tab === 'partner' ? <PartnerFinderPresentation /> : null}
      {tab === 'marketplace' ? <MarketplaceFinderPresentation /> : null}
      {tab === 'knights' ? <KnighstFinderPresentation /> : null}
      {tab === 'events' ? <EventsFinderPresentation /> : null}
      {tab === 'useCases' ? <UseCasesFinderPresentation /> : null}
      {tab === 'cloud_svcs' ? <CSPFinderPresentation /> : null}
      {(tab === 'partner' || tab === 'marketplace') && standalone ? (
        <StandaloneBanner marketplace={tab === 'marketplace'} />
      ) : null}
      <p className="text-[#92929d] text-xs">{t('disclaimer_landing')}</p>
    </div>
  );
}
